import { useMemo } from "react";
import * as React from "react";
import {
 IonButton,
 IonIcon,
 IonItem,
 IonList,
 IonListHeader,
 IonMenuButton,
 useIonPopover,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { keyOutline, logOutOutline, personCircleSharp } from "ionicons/icons";

import { useLayout } from "shared/contexts/LayoutProvider";
import { useAuth } from "shared/contexts/AuthProvider";
import secretaryNavItems from "secretary/app/layout/secretaryNavItems";
import parentNavItems from "parent/app/layout/parentNavItems";
import teacherNavItems from "teacher/app/layout/teacherNavItems";
import studentNavItems from "student/app/layout/studentNavItems";

const PopoverList: React.FC<{
 fullName: string;
 logout: () => void;
 onHide: () => void;
}> = ({ fullName, logout, onHide }) => {
 return (
  <IonList>
   <IonListHeader className="text-tertiary-default">{fullName}</IonListHeader>
   <IonItem button routerLink="/change-password" className="capitalize">
    <IonIcon slot="start" color="tertiary" icon={keyOutline} />
    Αλλαγή Κωδικού
   </IonItem>
   <IonItem
    button
    onClick={() => {
     logout();
     onHide();
    }}
    className="capitalize"
   >
    <IonIcon slot="start" color="tertiary" icon={logOutOutline} />
    Αποσύνδεση
   </IonItem>
  </IonList>
 );
};

export const NavBar = () => {
 const layout = useLayout();
 const auth = useAuth();
 const [present, dismiss] = useIonPopover(PopoverList, {
  fullName: auth.user?.fullName,
  logout: () => auth.logout(),
  onHide: () => dismiss(),
 });
 const menuItems = useMemo(
  () =>
   auth.user?.roles === "secretary"
    ? secretaryNavItems
    : auth.user?.roles === "parent"
    ? parentNavItems
    : auth.user?.roles === "teacher"
    ? teacherNavItems
    : studentNavItems,
  [auth]
 );

 return (
  <>
   {layout.view === "mobile" ? (
    <>
     <IonMenuButton
      onClick={async () => await menuController.toggle()}
     ></IonMenuButton>
    </>
   ) : (
    <>
     {menuItems.map((p, index) => (
      <IonButton routerLink={p.path} key={index} className="capitalize">
       <IonIcon slot="start" icon={p.icon} />
       {p.caption}
      </IonButton>
     ))}
     <IonButton
      onClick={(e: any) =>
       present({
        event: e.nativeEvent,
       })
      }
      className="normal-case bg-light-default text-dark-default"
     >
      {auth.user?.fullName}
      <IonIcon slot="start" icon={personCircleSharp} />
     </IonButton>
    </>
   )}
  </>
 );
};

export const UnAuthorizedNavBar = () => {
 return <div></div>;
};
