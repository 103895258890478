import { homeOutline } from "ionicons/icons";

const navItems = [
 {
  caption: "Μαθήματα",
  path: "/",
  icon: homeOutline,
 },
];

export default navItems;
