import { homeOutline } from "ionicons/icons";

const studentNavItems = [
 {
  caption: "Κεντρική",
  path: "/",
  icon: homeOutline,
 },
];

export default studentNavItems;
