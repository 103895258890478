import { useState } from 'react';
import {
 IonFooter,
 IonToolbar,
 IonTitle,
 IonBadge,
 IonItem,
} from '@ionic/react';

const Footer = () => {
 const [thisYear] = useState(new Date().getFullYear());
 return (
  <IonFooter>
   <IonToolbar className="h-10 flex flex-col justify-center items-center">
    <IonItem slot="start" className="text-center">
     'Εκδοση:
     <IonBadge className="ml-2" color="tertiary">
      {process.env.REACT_APP_VERSION}
     </IonBadge>
    </IonItem>
    <IonTitle size="small" className="text-center" color="dark">
     Copyrigths © {thisYear}.Το παρών site αποτελεί πνευματική ιδιοκτησία του
     φροντιστηρίου ΔΟΜΗ
    </IonTitle>
   </IonToolbar>
  </IonFooter>
 );
};

export default Footer;
