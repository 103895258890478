import { lazy } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";

const NotFound = lazy(() => import("shared/app/layout/NotFound"));
const Home = lazy(() => import("parent/features/Home/Home"));
const ExamsGrades = lazy(
 () => import("parent/features/ParentExamsGrades/pages/ParentExamsGrades")
);
const ViewExamsGrade = lazy(
 () =>
  import("secretary/features/ExamsGrades/pages/ViewExamsGrade/ViewExamsGrade")
);
const VerifyEmail = lazy(
 () => import("shared/features/User/VerifyEmail/VerifyEmail")
);
const ChangePassword = lazy(
 () => import("shared/features/User/ChangePassword/ChangePassword")
);
const ResetPassword = lazy(
 () => import("shared/features/User/ResetPassword/ResetPassword")
);

const ParentRoutes = () => {
 return (
  <IonRouterOutlet>
   <Route exact={true} path="/" component={Home} />
   <Route exact={true} path="/exams-grades" component={ExamsGrades} />
   <Route
    exact={true}
    path="/exams-grades/:userId"
    component={ViewExamsGrade}
   />
   <Route
    exact={true}
    path="verify-email/:verifyToken"
    component={VerifyEmail}
   />
   <Route
    exact={true}
    path="/reset-password/:resetLink"
    component={ResetPassword}
   />
   <Route exact path="/change-password" component={ChangePassword} />
   <Route component={NotFound} />
  </IonRouterOutlet>
 );
};

export default ParentRoutes;
