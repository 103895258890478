import { IonRouterOutlet } from "@ionic/react";
import { lazy } from "react";
import { Route } from "react-router-dom";

const Login = lazy(() => import("shared/features/User/Login/Login"));
const Register = lazy(() => import("shared/features/User/Register/Register"));
const ForgotPassword = lazy(
 () => import("shared/features/User/ForgotPassword/ForgotPassword")
);
const VerifyEmail = lazy(
 () => import("shared/features/User/VerifyEmail/VerifyEmail")
);
const ResetPassword = lazy(
 () => import("shared/features/User/ResetPassword/ResetPassword")
);
const PrivacyPolicy = lazy(() => import("shared/features/PrivacyPolicy"));
const UnAuthorizedRoutes = () => {
 return (
  <IonRouterOutlet>
   <Route path="/" component={Login} exact />
   <Route path="/register" component={Register} exact />
   <Route path="/forgot-password" component={ForgotPassword} exact />
   <Route path="/verify-email/:verifyToken" component={VerifyEmail} exact />
   <Route path="/reset-password/:resetLink" component={ResetPassword} exact />
   <Route path="/privacy-policy" component={PrivacyPolicy} exact />
   <Route component={Login} />
  </IonRouterOutlet>
 );
};

export default UnAuthorizedRoutes;
