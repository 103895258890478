import { Fragment, useMemo, useState } from "react";
import {
 IonContent,
 IonHeader,
 IonIcon,
 IonItem,
 IonLabel,
 IonList,
 IonMenu,
 IonMenuToggle,
 IonTitle,
 IonToolbar,
} from "@ionic/react";
import {
 chevronDownOutline,
 chevronUpOutline,
 keyOutline,
 logOutOutline,
} from "ionicons/icons";

import { useAuth } from "shared/contexts/AuthProvider";
import secretaryNavItems from "secretary/app/layout/secretaryNavItems";
import parentNavItems from "parent/app/layout/parentNavItems";
import studentNavItems from "student/app/layout/studentNavItems";
import teacherNavItems from "teacher/app/layout/teacherNavItems";

const MobileSidebar = () => {
 //there must be condition per role
 const auth = useAuth();
 const [showMenuId, setShowMenuId]: any = useState("");
 const menuItems = useMemo(
  () =>
   auth.user?.roles === "secretary"
    ? secretaryNavItems
    : auth.user?.roles === "parent"
    ? parentNavItems
    : auth.user?.roles === "teacher"
    ? teacherNavItems
    : studentNavItems,
  [auth]
 );
 return (
  <IonMenu side="end" contentId="main" id="main" menuId="mobileSidebar">
   <IonHeader>
    <IonToolbar color="light">
     <IonTitle>Δομή e-class</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent>
    <IonList>
     <>
      {menuItems.map((p: any, index) => (
       <Fragment key={index}>
        {/* MENU ITEM CHECK IF HAVE SUBMENUS*/}
        {p.items !== undefined ? (
         <IonItem
          button
          onClick={() =>
           setShowMenuId((state: string) => (state === "" ? p.path : ""))
          }
         >
          <IonIcon slot="start" icon={p.icon} color="tertiary" />
          <IonLabel> {p.caption}</IonLabel>
          <IonIcon
           slot="end"
           icon={showMenuId === p.path ? chevronUpOutline : chevronDownOutline}
           color="tertiary"
          />
         </IonItem>
        ) : (
         <IonMenuToggle>
          <IonItem
           button
           routerLink={p.path}
           routerDirection="none"
           key={index}
          >
           <IonIcon slot="start" icon={p.icon} color="tertiary" />
           <IonLabel> {p.caption}</IonLabel>
          </IonItem>
         </IonMenuToggle>
        )}
        {/* SUBMENU ITEMS  */}
        {showMenuId === p.path &&
         p.items.map((i: any, index: number) => (
          <IonMenuToggle key={index}>
           <IonItem
            className="pl-6"
            button
            routerLink={i.path}
            routerDirection="none"
           >
            <IonIcon slot="start" icon={i.icon} color="tertiary" />
            <IonLabel> {i.caption}</IonLabel>
           </IonItem>
          </IonMenuToggle>
         ))}
       </Fragment>
      ))}
      {/* LOGOUT - CHANGE_PASS BUTTON */}
      <IonMenuToggle>
       <IonItem button routerLink="/change-password" className="capitalize">
        <IonIcon slot="start" color="tertiary" icon={keyOutline} />
        Αλλαγή Κωδικού
       </IonItem>
       <IonItem button routerDirection="none" onClick={() => auth.logout()}>
        <IonIcon slot="start" icon={logOutOutline} color="tertiary" />
        <IonLabel>Αποσύνδεση</IonLabel>
       </IonItem>
      </IonMenuToggle>
     </>
    </IonList>
   </IonContent>
  </IonMenu>
 );
};

export default MobileSidebar;
