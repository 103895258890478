import { Suspense } from 'react';
import { IonApp, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from 'react-query';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* TailwindCSS */
import 'shared/app/theme/tailwind.css';

/* Theme variables */
import 'shared/app/theme/variables.css';
import Layout from 'shared/app/layout/Layout';
import { ToastProvider } from 'shared/hooks/useToasts';
import MobileSidebar from 'shared/app/layout/navMenu/MobileSidebar';
import AuthProvider from 'shared/contexts/AuthProvider';
import { LayoutProvider } from 'shared/contexts/LayoutProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient({
 defaultOptions: {
  queries: {
   retry: false,
   suspense: true,
   refetchOnWindowFocus: false,
   staleTime: 60 * 1000 * 5,
  },
 },
});

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

const App = () => (
 <IonApp>
  <IonReactRouter>
   <GoogleOAuthProvider clientId={clientId}>
    <QueryClientProvider client={queryClient}>
     <Suspense fallback={<IonLoading isOpen={true} spinner="crescent" />}>
      <ToastProvider
       value={{ duration: 3000, position: 'top', animated: true, mode: 'ios' }}
      >
       <AuthProvider>
        <LayoutProvider>
         <MobileSidebar />
         <Layout />
        </LayoutProvider>
       </AuthProvider>
      </ToastProvider>
     </Suspense>
    </QueryClientProvider>
   </GoogleOAuthProvider>
  </IonReactRouter>
 </IonApp>
);

export default App;
