import { useCallback } from 'react';
import {
 IonButtons,
 IonContent,
 IonGrid,
 IonHeader,
 IonIcon,
 IonItem,
 IonRow,
 IonToggle,
 IonToolbar,
} from '@ionic/react';
import { bookOutline, moon } from 'ionicons/icons';
import { Link } from 'react-router-dom';

import styles from 'shared/app/layout/layout.module.css';
import useDarkMode from 'shared/hooks/useDarkMode';
import { useLayout } from 'shared/contexts/LayoutProvider';
import SecretaryRoutes from 'secretary/app/layout/SecretaryRoutes';
import ParentRoutes from 'parent/app/layout/ParentRoutes';
import Footer from 'shared/app/layout/Footer';
import { NavBar, UnAuthorizedNavBar } from 'shared/app/layout/navMenu/NavBar';
import MobileSidebar from 'shared/app/layout/navMenu/MobileSidebar';
import UnAuthorizedRoutes from 'shared/app/layout/UnAuthorizedRoutes';
import TeacherRoutes from 'teacher/app/layout/TeacherRoutes';
import StudentRoutes from 'student/app/layout/StudentRoutes';

const Layout = () => {
 const { isDark, toggleDarkModeHandler } = useDarkMode();
 const layout = useLayout();

 const NavMenuSelector = useCallback(() => {
  return layout.auth() ? <NavBar /> : <UnAuthorizedNavBar />;
 }, [layout]);
 const RoutesSelector = useCallback(() => {
  if (layout.auth()) {
   if (layout.role === 'secretary') {
    return <SecretaryRoutes />;
   } else if (layout.role === 'parent') {
    return <ParentRoutes />;
   } else if (layout.role === 'teacher') {
    return <TeacherRoutes />;
   } else if (layout.role === 'student') {
    return <StudentRoutes />;
   }
  }

  return <UnAuthorizedRoutes />;
 }, [layout]);

 return (
  <>
   <MobileSidebar />
   <IonHeader>
    <IonToolbar color="tertiary">
     <IonGrid>
      <IonRow className="flex flex-row justify-between">
       <Link
        to="/"
        className={`${styles.logo} self-center flex items-center justify-center`}
       >
        ΔΟΜΗ
        <IonIcon icon={bookOutline} className="ml-2" />
       </Link>
       <IonItem color="tertiary">
        <IonIcon slot="start" icon={moon} className="m-0" />
        <IonToggle
         checked={isDark === 'true'}
         slot="end"
         name="darkMode"
         color="light"
         onIonChange={toggleDarkModeHandler}
        />
       </IonItem>
       <IonButtons color="tertiary">
        <NavMenuSelector />
       </IonButtons>
      </IonRow>
     </IonGrid>
    </IonToolbar>
   </IonHeader>
   <IonContent
    className="flex flex-row justify-center items-center p-2 overflow-auto"
    scrollEvents={false}
    scrollX={true}
    scrollY={true}
   >
    <RoutesSelector />
   </IonContent>

   {layout.view === 'web' && <Footer />}
  </>
 );
};

export default Layout;
