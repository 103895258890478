import { lazy } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";

const NotFound = lazy(() => import("shared/app/layout/NotFound"));
const Home = lazy(() => import("teacher/features/Home/Home"));
const Lesson = lazy(() => import("teacher/features/Lesson/pages/Lesson"));
const Questions = lazy(() =>
 import("teacher/features/Lesson/pages/Questions").then((module) => ({
  default: module.Questions,
 }))
);
const CreateQuestion = lazy(() =>
 import("teacher/features/Lesson/pages/Questions").then((module) => ({
  default: module.CreateQuestion,
 }))
);
const EditQuestion = lazy(() =>
 import("teacher/features/Lesson/pages/Questions").then((module) => ({
  default: module.EditQuestions,
 }))
);
const StudentsSubmits = lazy(() =>
 import("teacher/features/Lesson/pages/StudentsSubmits").then((module) => ({
  default: module.StudentsSubmits,
 }))
);

const StudentGrades = lazy(() =>
 import("teacher/features/Lesson/pages/StudentsSubmits").then((module) => ({
  default: module.StudentGrades,
 }))
);

const Documents = lazy(() =>
 import("teacher/features/Lesson/pages/Documents").then((module) => ({
  default: module.Documents,
 }))
);

const UploadDocuments = lazy(() =>
 import("teacher/features/Lesson/pages/Documents").then((module) => ({
  default: module.UploadDocuments,
 }))
);

const VerifyEmail = lazy(
 () => import("shared/features/User/VerifyEmail/VerifyEmail")
);
const ChangePassword = lazy(
 () => import("shared/features/User/ChangePassword/ChangePassword")
);
const ResetPassword = lazy(
 () => import("shared/features/User/ResetPassword/ResetPassword")
);

const TeacherRoutes = () => {
 return (
  <IonRouterOutlet>
   <Route exact={true} path="/" component={Home} />
   <Route
    exact={true}
    path="verify-email/:verifyToken"
    component={VerifyEmail}
   />
   <Route
    exact={true}
    path="/reset-password/:resetLink"
    component={ResetPassword}
   />
   <Route exact={true} path="/change-password" component={ChangePassword} />
   <Route
    exact={true}
    path="/excercises/:excerciseId/lesson/:lessonId/questions"
    component={Questions}
   />
   <Route
    exact={true}
    path="/excercises/:excerciseId/lesson/:lessonId/questions/new"
    component={CreateQuestion}
   />
   <Route
    exact={true}
    path="/excercises/:excerciseId/lesson/:lessonId/questions/edit/:id"
    component={EditQuestion}
   />
   <Route
    exact={true}
    path="/excercises/:excerciseId/lesson/:lessonId/students"
    component={StudentsSubmits}
   />
   <Route
    exact={true}
    path="/excercises/:excerciseId/lesson/:lessonId/student/:studentId"
    component={StudentGrades}
   />
   <Route
    exact={true}
    path="/documents/lesson/:lessonId/chapter/:chapterId"
    component={Documents}
   />
   <Route
    exact={true}
    path="/documents/lesson/:lessonId/chapter/:chapterId/upload"
    component={UploadDocuments}
   />
   <Route path="/lesson/:lessonId" component={Lesson} />
   <Route component={NotFound} />
  </IonRouterOutlet>
 );
};

export default TeacherRoutes;
