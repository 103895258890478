import {
 peopleOutline,
 bookOutline,
 desktopOutline,
 pencilOutline,
 peopleCircleOutline,
 clipboardOutline,
 gitBranchOutline,
} from 'ionicons/icons';

const navItems = [
 {
  caption: 'Χρήστες',
  path: '/users',
  icon: peopleOutline,
  items: [
   {
    caption: 'Μαθητές',
    path: '/users?role=student',
    icon: bookOutline,
   },
   {
    caption: 'Καθηγητές',
    path: '/users?role=teacher',
    icon: desktopOutline,
   },
   {
    caption: 'Γραμματεία',
    path: '/users?role=secretary',
    icon: pencilOutline,
   },
   {
    caption: 'Γονείς',
    path: '/users?role=parent',
    icon: peopleCircleOutline,
   },
  ],
 },
 {
  caption: 'Βαθμολογίες',
  path: '/exams-grades',
  icon: clipboardOutline,
 },
 {
  caption: 'Τμήματα',
  path: '/departments',
  icon: gitBranchOutline,
 },
 {
  caption: 'Μαθήματα',
  path: '/lessons',
  icon: bookOutline,
 },
];
export default navItems;
