import { lazy } from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router-dom';

const Home = lazy(() => import('secretary/features/Home/Home'));
const Users = lazy(() => import('secretary/features/Users/pages/Users/Users'));
const EditUser = lazy(
 () => import('secretary/features/Users/pages/EditUser/EditUser')
);
const CreateUser = lazy(
 () => import('secretary/features/Users/pages/CreateUser/CreateUser')
);
const ExamsGrades = lazy(
 () => import('secretary/features/ExamsGrades/pages/ExamsGrades/ExamsGrades')
);
const ViewExamsGrade = lazy(
 () =>
  import('secretary/features/ExamsGrades/pages/ViewExamsGrade/ViewExamsGrade')
);
const Departments = lazy(
 () => import('secretary/features/Departments/pages/Departments/Departments')
);
const Lessons = lazy(
 () => import('secretary/features/Lessons/pages/Lessons/Lessons')
);

const NotifyUsers = lazy(() => import('secretary/features/NotifyUsers'));
const VerifyEmail = lazy(
 () => import('shared/features/User/VerifyEmail/VerifyEmail')
);
const ResetPassword = lazy(
 () => import('shared/features/User/ResetPassword/ResetPassword')
);

const ChangePassword = lazy(
 () => import('shared/features/User/ChangePassword/ChangePassword')
);

const NotFound = lazy(() => import('shared/app/layout/NotFound'));

const SecretaryRoutes = () => {
 return (
  <IonRouterOutlet>
   <Route exact path="/" component={Home} />
   <Route exact path="/users" component={Users} />
   <Route exact path="/user/new" component={CreateUser} />
   <Route exact path="/users/:id" component={EditUser} />
   <Route exact path="/exams-grades" component={ExamsGrades} />
   <Route exact path="/exams-grades/:userId" component={ViewExamsGrade} />
   <Route exact path="/departments" component={Departments} />
   <Route exact path="/lessons" component={Lessons} />
   <Route exact path="verify-email/:verifyToken" component={VerifyEmail} />
   <Route exact path="/reset-password/:resetLink" component={ResetPassword} />
   <Route exact path="/change-password" component={ChangePassword} />
   <Route exact path="/notify-users" component={NotifyUsers} />
   <Route component={NotFound} />
  </IonRouterOutlet>
 );
};

export default SecretaryRoutes;
