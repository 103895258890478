import { homeOutline, clipboardOutline } from 'ionicons/icons';

const navItems = [
 {
  caption: 'Κεντρική',
  path: '/',
  icon: homeOutline,
 },
 {
  caption: 'Βαθμολογίες',
  path: '/exams-grades',
  icon: clipboardOutline,
 },
];

export default navItems;
