import { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "shared/contexts/AuthProvider";
import { useMemo } from "react";

type LayoutContextProps = {
 role: string;
 view: string;
 auth: () => boolean;
};

const TABLET_BREAKPOINT = 1000;
const MOBILE_BREAKPOINT = 767;
export const LayoutContext = createContext<LayoutContextProps>({
 role: "student",
 view: "web",
 auth: () => false,
});

export const LayoutProvider = ({ children }: any) => {
 const auth = useAuth();
 const [layout, setLayout] = useState({
  role: "student",
  view:
   window.innerWidth <= MOBILE_BREAKPOINT
    ? "mobile"
    : window.innerWidth <= TABLET_BREAKPOINT
    ? "tablet"
    : "web",
  auth: auth.isAuthenticated,
 });

 useEffect(() => {
  const handleResize = () => {
   if (window.innerWidth <= MOBILE_BREAKPOINT && layout.view !== "mobile") {
    setLayout((prevLayout) => ({ ...prevLayout, view: "mobile" }));
   }
   if (window.innerWidth > TABLET_BREAKPOINT && layout.view !== "web") {
    setLayout((prevLayout) => ({ ...prevLayout, view: "web" }));
   }
   if (
    window.innerWidth <= TABLET_BREAKPOINT &&
    window.innerWidth >= MOBILE_BREAKPOINT &&
    layout.view !== "tablet"
   ) {
    setLayout((prevLayout) => ({ ...prevLayout, view: "tablet" }));
   }
  };
  window.addEventListener("resize", handleResize);
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, [layout]);

 useEffect(() => {
  const role = auth.user?.roles! || "student";
  setLayout((prevLayout) => ({
   ...prevLayout,
   auth: auth.isAuthenticated,
   role: role,
  }));
 }, [auth]);
 const values = useMemo(() => layout, [layout]);
 return (
  <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>
 );
};

export const useLayout = () => useContext(LayoutContext);
